/* eslint-disable max-lines */
import { makeStyles } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import CancelIcon from '@material-ui/icons/Cancel';
import { PENDING_STATUS } from 'modules/new-applications/components/Application';
import {
  AUTO_DECISIONS_OPTIONS,
  ENTITY_TYPE_OPTIONS,
  LEAD_ENTITY_TYPE_OPTIONS,
  LOADED_IN_SYSTEM_OPTIONS,
  WATCHTOWER_OPTIONS,
} from 'modules/reporting/constants';
import BorderedAutocomplete from 'modules/shared/components/inputs/BorderedAutocomplete';
import BorderedPeriodSelect from 'modules/shared/components/inputs/BorderedPeriodSelect';
import BorderedRegionStatesSelect from 'modules/shared/components/inputs/BorderedRegionStatesSelect';
import BorderedSelect from 'modules/shared/components/inputs/BorderedSelect';
import BorderedTextField from 'modules/shared/components/inputs/BorderedTextField';
import Switch from 'modules/shared/components/widgets/interactive/Switch';
import { isHeadquarter } from 'modules/shared/helpers/headquarterDetect';
import PropTypes from 'prop-types';
import React, { Fragment, useState } from 'react';
import getAccountTypeOptions from 'utils/getAccountTypeOptions';

import styles from './css/useFilterState.css';
import { FEATURE_FLAGS } from 'conf';
import { addFlaggedArray } from 'conf/flaggingHelpers';

export const drawerStyles = makeStyles(() => {
  return {
    paper: {
      paddingTop: '66px',
      width: '270px',
    },
  };
});

function AutocompleteFilter(props) {
  const { label, name, options, onChangeFilterParams, value } = props;
  const selectedOption = options.find((option) => option.value === value);

  const onChange = (_, selected) => {
    onChangeFilterParams({ [name]: selected.value });
  };

  return (
    <BorderedAutocomplete
      textFieldProps={{
        label,
      }}
      options={options}
      value={selectedOption}
      onChange={onChange}
    />
  );
}

export function Filter(props) {
  const { filterParams, isStatesOptionsVisible, onChangeFilterParams, name } =
    props;
  const { label, options, rawOptions, type, visible } = props.filterConfig;

  if (!visible) {
    return <Fragment />;
  }

  switch (type) {
    case 'autocomplete':
      return (
        <AutocompleteFilter
          label={label}
          name={name}
          onChangeFilterParams={onChangeFilterParams}
          options={options}
          value={filterParams[name]}
        />
      );
    case 'dropdown':
      return (
        <BorderedSelect
          label={label}
          name={name}
          value={filterParams[name]}
          options={options}
          rawOptions={rawOptions}
          onChange={(res) => onChangeFilterParams({ [name]: res.target.value })}
          MenuProps={{
            style: {
              maxHeight: 300,
            },
          }}
        />
      );
    case 'date_picker':
      return (
        <BorderedPeriodSelect
          label={label}
          name={name}
          MenuProps={{
            style: {
              maxHeight: 300,
            },
          }}
          periodValue={filterParams[name]}
          isFutureDate={props.filterConfig.isFutureDate}
          periodFromToValues={{
            from: filterParams[`${name}_from`],
            to: filterParams[`${name}_to`],
          }}
          handleChange={(res) => {
            onChangeFilterParams({
              [name]: res.value,
              [`${name}_from`]: res.from,
              [`${name}_to`]: res.to,
            });
          }}
        />
      );
    case 'input':
      return (
        <BorderedTextField
          name={name}
          label={label}
          value={filterParams[name]}
          onChange={(event) =>
            onChangeFilterParams({ [name]: event.target.value })
          }
        />
      );
    case 'region_select':
      return (
        <BorderedRegionStatesSelect
          label={'Business location'}
          values={{ region: filterParams.region, state: filterParams.state }}
          isStatesOptionsVisible={isStatesOptionsVisible}
          handleChange={(res) => onChangeFilterParams(res)}
        />
      );
  }
}

function LimitSwitch(props) {
  const { filterParams, filterType, onChangeFilterParams } = props;

  if (filterType === 'application') {
    return (
      <Switch
        leftOption="requested_limits"
        rightOption="approved_limits"
        leftLabel="Requested limits"
        rightLabel="Approved limits"
        current={filterParams.limit_type}
        handleChange={(value) => onChangeFilterParams({ limit_type: value })}
        grey={true}
      />
    );
  }

  return null;
}

function LimitRange(props) {
  const { filterParams, filterType, onChangeFilterParams } = props;

  if (filterType === 'application' || filterType === 'customer') {
    return (
      <div className={styles.limits}>
        <span className={styles.limit_text}>Limit range</span>
        <div className={styles.limit_inputs}>
          <BorderedTextField
            label={'min'}
            value={filterParams.limit_min}
            onChange={(event) =>
              onChangeFilterParams({ limit_min: event.target.value })
            }
          />
        </div>
        <span className={styles.limit_text}>to</span>
        <div className={styles.limit_inputs}>
          <BorderedTextField
            label={'max'}
            value={filterParams.limit_max}
            onChange={(event) =>
              onChangeFilterParams({ limit_max: event.target.value })
            }
          />
        </div>
      </div>
    );
  }

  return null;
}

function FilterList(props) {
  const {
    dynamicFilterOptions,
    filterParams,
    filterType,
    isOpen,
    isStatesOptionsVisible,
    onChangeFilterParams,
    onToggleIsOpen,
    // TODO - We will refactor the rest of the filters eventually to adapt passFilter
    passedFilters,
    reset,
    isCallCentreActive,
  } = props;

  const [isSalesChannelSelected, setIsSalesChannelSelected] = useState(false);

  const applicationTypeOptions = [
    { label: 'All', value: 'All' },
    ...getAccountTypeOptions(),
  ];

  function getFiltersByType({
    alertTypeOptions,
    approverOptions,
    branchOptions,
    filterType,
    pendingStatusOptions,
    applicationVersionOptions,
    untaggedApplicationOptions,
    userOptions,
    isCallCentreActive,
    callCentreUsers,
  }) {
    if (filterType === 'application') {
      const result = [];
      result.push({
        key: 'start_date',
        label: 'Start date',
        type: 'date_picker',
        visible: true,
      });
      result.push({
        key: 'pending_state',
        label: 'Pending status',
        options: pendingStatusOptions,
        type: 'dropdown',
        visible: true,
      });
      result.push({
        key: 'application_version',
        label: 'Application Version',
        options: applicationVersionOptions,
        type: 'dropdown',
        visible: true,
      });
      result.push({
        key: 'branch',
        label: 'Tier',
        options: branchOptions,
        type: 'dropdown',
        visible: isHeadquarter(),
      });
      result.push({
        key: 'owner',
        label: 'Owner',
        options: userOptions,
        type: 'dropdown',
        visible: !isHeadquarter(),
      });
      result.push({
        key: 'approver',
        label: 'Approver',
        options: approverOptions,
        type: 'dropdown',
        visible: true,
      });
      result.push({
        key: 'auto_decision',
        label: 'Auto decisions',
        options: AUTO_DECISIONS_OPTIONS,
        type: 'dropdown',
        visible: true,
      });
      result.push({
        key: 'sales_channel',
        label: 'Sales channels',
        onChangeFilterParams: (value) => {
          setIsSalesChannelSelected(
            value.sales_channel === 'call_centre_channel'
          );
          onChangeFilterParams(value);
        },
        options: getSalesChannelsOptions(isCallCentreActive),
        type: 'dropdown',
        visible: true,
      });
      result.push({
        key: 'call_centre_user',
        label: 'Call centre user',
        options: callCentreUsers,
        type: 'dropdown',
        visible: isSalesChannelSelected,
      });
      result.push({
        key: 'untagged',
        label: 'Untagged application',
        options: untaggedApplicationOptions,
        type: 'dropdown',
        visible: true,
      });
      result.push({
        isFutureDate: true,
        key: 'review_date',
        label: 'Review date',
        type: 'date_picker',
        visible: true,
      });
      result.push({
        key: 'application_type',
        label: 'Account type',
        options: applicationTypeOptions,
        type: 'dropdown',
        visible: true,
      });
      result.push({
        key: 'entity_type',
        label: 'Entity type',
        options: ENTITY_TYPE_OPTIONS,
        type: 'dropdown',
        visible: true,
      });
      result.push({
        key: 'location',
        label: 'Business location',
        type: 'region_select',
        visible: true,
      });
      result.push({
        key: 'loaded_in_system',
        label: 'Loaded in system',
        options: LOADED_IN_SYSTEM_OPTIONS,
        type: 'dropdown',
        visible: true,
      });
      result.push({
        key: 'with_watchtower_alerts',
        label: 'Watchtower',
        options: WATCHTOWER_OPTIONS,
        type: 'dropdown',
        visible: FEATURE_FLAGS.FEATURE_FLAG_WATCHTOWER,
      });
      return result;
    }

    if (filterType === 'lead') {
      return [
        {
          key: 'start_date',
          label: 'Start date',
          type: 'date_picker',
          visible: true,
        },
        {
          key: isHeadquarter() ? 'branch' : 'initiator',
          label: 'Initiated by',
          options: isHeadquarter() ? branchOptions : userOptions,
          type: 'dropdown',
          visible: true,
        },
        {
          key: 'entity_type',
          label: 'Entity type',
          options: LEAD_ENTITY_TYPE_OPTIONS,
          type: 'dropdown',
          visible: true,
        },
        {
          key: 'location',
          label: 'Business location',
          type: 'region_select',
          visible: true,
        },
      ];
    }

    // TODO - Refer to task/7277 to utilize passedFilters props
    // instead adding more conditional rendering statements
    if (filterType === 'alerts') {
      return [
        {
          key: 'alert_type',
          label: 'Alert',
          options: alertTypeOptions,
          type: 'dropdown',
          visible: true,
        },
        {
          key: 'alert_date',
          label: 'Alert date',
          type: 'date_picker',
          visible: true,
        },
      ];
    }

    if (filterType === 'watchtower') {
      return [
        {
          key: 'alert_type',
          label: 'Alert type',
          options: watchtowerAlertTypeOptions,
          type: 'dropdown',
          visible: true,
        },
        {
          key: 'alert_trigger',
          label: 'Alert trigger',
          options: watchtowerAlertTriggerOptions,
          type: 'dropdown',
          visible: true,
        },
        {
          key: 'alert_date',
          label: 'Alert date',
          type: 'date_picker',
          visible: true,
        },
        {
          key: 'alert_actioned',
          label: 'Actioned',
          options: watchtowerActionedOptions,
          type: 'dropdown',
          visible: true,
        },
        {
          key: 'alert_irregular',
          label: 'Irregular accounts',
          options: watchtowerIrregularOptions,
          type: 'dropdown',
          visible: true,
        },
        {
          key: 'alert_group',
          label: 'Application group',
          options: watchtowerGroupOptions,
          type: 'dropdown',
          visible: true,
        },
        {
          key: 'alert_futuretask',
          label: FEATURE_FLAGS.FEATURE_FLAG_UPDATED_WATCHTOWER_VCF
            ? 'Account monitoring'
            : 'Future tasks',
          options: [{ label: 'All', value: 'All' }],
          type: 'dropdown',
          visible: true,
        },
        {
          key: 'alert_pipeline',
          label: 'Pipelines',
          options: watchtowerPipelineOptions,
          type: 'dropdown',
          visible: true,
        },
      ];
    }

    if (filterType === 'customer') {
      return [
        {
          key: 'start_date',
          label: 'Start date',
          type: 'date_picker',
          visible: true,
        },
        {
          key: 'approved_at',
          label: 'Approval/Activate date',
          type: 'date_picker',
          visible: true,
        },
        {
          key: 'source',
          label: 'Source',
          options: customerSourceOptions,
          type: 'dropdown',
          visible: true,
        },
        {
          key: 'branch',
          label: 'Tier',
          options: branchOptions,
          type: 'dropdown',
          visible: isHeadquarter(),
        },
        {
          key: 'sales_channel',
          label: 'Sales channels',
          options: getSalesChannelsOptions(isCallCentreActive),
          type: 'dropdown',
          visible: true,
        },
        {
          key: 'location',
          label: 'Customer location',
          type: 'region_select',
          visible: true,
        },
        {
          key: 'application_type',
          label: 'Account type',
          options: applicationTypeOptions,
          type: 'dropdown',
          visible: true,
        },
        {
          key: 'entity_type',
          label: 'Entity type',
          options: ENTITY_TYPE_OPTIONS,
          type: 'dropdown',
          visible: true,
        },
        {
          key: 'approver',
          label: 'Approver',
          options: approverOptions,
          type: 'dropdown',
          visible: true,
        },
        {
          isFutureDate: true,
          key: 'review_date',
          label: 'Review date',
          type: 'date_picker',
          visible: true,
        },
        {
          key: 'with_watchtower_alerts',
          label: 'Watchtower',
          options: customerWatchtowerOptions,
          type: 'dropdown',
          visible: FEATURE_FLAGS.FEATURE_FLAG_WATCHTOWER,
        },
        {
          key: 'ppsr_applies',
          label: 'PPSR',
          options: customerPpsrOptions,
          type: 'dropdown',
          visible: true,
        },
      ];
    }

    return [];
  }

  const approverOptions = [{ label: 'All', value: 'All' }].concat(
    dynamicFilterOptions.approver_options || []
  );

  const branchOptions = [{ label: 'All', value: 'All' }].concat(
    dynamicFilterOptions.branch_options || []
  );

  const untaggedApplicationOptions = [{ label: 'All', value: 'All' }].concat(
    dynamicFilterOptions.approval_hierarchy_levels || []
  );

  const alertTypeOptions = [{ label: 'All', value: 'All' }].concat(
    dynamicFilterOptions.alert_type || []
  );

  const watchtowerAlertTypeOptions = [
    { label: 'All', value: 'All' },
    { label: 'Internal', value: 'internal' },
    { label: 'External', value: 'external' },
  ].concat(dynamicFilterOptions.alert_type || []);

  const watchtowerActionedOptions = [
    { label: 'All', value: 'All' },
    { label: 'Actioned', value: 'actioned' },
    { label: 'Unactioned', value: 'unactioned' },
  ].concat(dynamicFilterOptions.alert_actioned || []);

  const watchtowerAlertTriggerOptions = [
    { label: 'All', value: 'All' },
    {
      label: 'Change of key person/s (NZ)',
      value: 'nz_company_persons_changed',
    },
    {
      label: 'Change of key person/s (AU)',
      value: 'au_company_persons_changed',
    },
    { label: 'Credit score decrease', value: 'credit_score_decrease' },
    { label: 'Deregistered (NZ)', value: 'nz_company_deregistered' },
    { label: 'Deregistered (AU)', value: 'au_company_deregistered' },
    { label: 'Director mismatch (AU)', value: 'au_company_director_mismatch' },
    { label: 'GST registered (AU)', value: 'au_company_gst_registered' },
    { label: 'Insolvency (AU)', value: 'au_company_insolvency' },
    { label: 'Insolvency(NZ)', value: 'nz_company_insolvency' },
    { label: 'Liquidation (NZ)', value: 'nz_company_liquidation' },
    { label: 'Liquidation (AU)', value: 'au_company_liquidation' },
    { label: 'Receivership (AU)', value: 'au_company_receivership' },
    { label: 'Receivership (NZ)', value: 'nz_company_receivership' },
    {
      label: 'Voluntary administration (AU)',
      value: 'au_company_voluntary_administration',
    },
    {
      label: 'Voluntary administration (NZ)',
      value: 'nz_company_voluntary_administration',
    },
    { label: 'Application velocity', value: 'application_velocity' },
    {
      label: 'Director from Liquidated company',
      value: 'director_liquidated_company',
    },
    { label: 'Email age', value: 'email_age' },
    ...addFlaggedArray('FEATURE_FLAG_WATCHTOWER_EMAIL_DOMAINS', [
      { label: 'Email client domain', value: 'email_domains' },
    ]),
    { label: 'Foreign IP addresses', value: 'blocked_geo_ips' },
    {
      label: 'Identical ABN/ACN/NZBN/NZCN',
      value: 'identical_company_numbers',
    },
    { label: 'Identical directors', value: 'identical_directors' },
    { label: 'Identical emails', value: 'identical_emails' },
    { label: 'Identical IDs', value: 'identical_identifications' },
    { label: 'Identical IP addresses', value: 'identical_ip_addresses' },
    { label: 'Repeat applications', value: 'repeat_application' },
    { label: 'Unusual application time', value: 'unusual_application_times' },
  ];

  const watchtowerIrregularOptions = [
    { label: 'All', value: 'All' },
    { label: 'Irregular', value: 'irregular' },
  ];

  const watchtowerGroupOptions = [
    { label: 'All', value: 'All' },
    { label: 'Grouped', value: 'grouped' },
    { label: 'Ungrouped', value: 'ungrouped' },
  ];

  const watchtowerPipelineOptions = [
    { label: 'All', value: 'All' },
    { label: 'Sales', value: 'sales' },
    { label: 'Customers', value: 'customers' },
  ];

  const pendingStatusOptions = [
    { label: 'All', value: 'All' },
    { label: 'No pending status', value: 'no_pending' },
    ...PENDING_STATUS,
  ];

  const applicationVersionOptions = [{ label: 'All', value: 'All' }].concat(
    dynamicFilterOptions.application_versions
      ? dynamicFilterOptions.application_versions.sort((a, b) => {
          if (a.value > b.value) return 1;
          if (a.value < b.value) return -1;
          return 0;
        })
      : []
  );

  const userOptions = [{ label: 'All', value: 'All' }].concat(
    dynamicFilterOptions.user_options || []
  );

  const callCentreUsers = [{ label: 'All', value: 'All' }].concat(
    dynamicFilterOptions.call_centre_user_options || []
  );

  const customerSourceOptions = [
    { label: 'All', value: 'All' },
    { label: '1Centre', value: 'regular' },
    { label: 'Rapid transfer', value: 'bulk' },
  ];

  const customerWatchtowerOptions = [
    { label: 'All', value: 'All' },
    { label: 'Irregular', value: 'irregular' },
    { label: 'Grouped', value: 'grouped' },
    { label: 'N/A', value: false },
  ];

  const customerPpsrOptions = [
    { label: 'All', value: 'All' },
    { label: 'PPSR apply', value: true },
    { label: 'N/A', value: false },
  ];

  const getSalesChannelsOptions = (isCallCentreActive) => {
    const options = [
      { label: 'All', value: 'All' },
      { label: 'Rep channel', value: 'rep_channel' },
      { label: 'Website channel', value: 'website_channel' },
      { label: 'Location channel', value: 'qr_code_channel' },
    ];
    if (isCallCentreActive) {
      options.push({
        label: 'Call Centre channel',
        value: 'call_centre_channel',
      });
    }
    return options;
  };

  const filters =
    passedFilters ||
    getFiltersByType({
      alertTypeOptions,
      applicationVersionOptions,
      approverOptions,
      branchOptions,
      callCentreUsers,
      filterType,
      isCallCentreActive,
      pendingStatusOptions,
      untaggedApplicationOptions,
      userOptions,
    });

  return (
    <Drawer
      anchor={'right'}
      open={isOpen}
      variant="persistent"
      classes={drawerStyles()}
    >
      <div className={styles.header}>
        <CancelIcon className={styles.cancel_icon} onClick={onToggleIsOpen} />
        <div className={styles.reset_button} onClick={reset}>
          Reset
        </div>
      </div>
      <div className={styles.filters}>
        <LimitSwitch
          filterParams={filterParams}
          filterType={filterType}
          onChangeFilterParams={onChangeFilterParams}
        />
        <LimitRange
          filterParams={filterParams}
          filterType={filterType}
          onChangeFilterParams={onChangeFilterParams}
        />
        {filters.map((filter) => (
          <Filter
            key={filter.key}
            name={filter.key}
            filterConfig={filter}
            filterParams={filterParams}
            isStatesOptionsVisible={isStatesOptionsVisible}
            onChangeFilterParams={
              filter.onChangeFilterParams || onChangeFilterParams
            }
          />
        ))}
      </div>
    </Drawer>
  );
}

FilterList.defaultProps = {
  filterType: 'application',
  isStatesOptionsVisible: true,
};

FilterList.propTypes = {
  filterType: PropTypes.oneOf(['application', 'lead', 'alerts', 'customer']),
};

const defaultValues = {
  application_type: 'All',
  application_version: 'All',
  approver: 'All',
  auto_decision: 'All',
  branch: 'All',
  call_centre_user: 'All',
  collateral_type: 'All',
  entity_type: 'All',
  expiry_date: 'All',
  initiator: 'All',
  limit_max: '',
  limit_min: '',
  limit_type: 'requested_limits',
  loaded_in_system: 'All',
  owner: 'All',
  pending_state: 'All',
  region: 'All',
  review_date: 'All',
  sales_channel: 'All',
  start_date: 'All',
  state: 'All',
  untagged: 'All',
  purchase_money_enabled: 'All',
  with_watchtower_alerts: 'All',
};

const defaultAlertsValues = {
  alert_date: 'All',
  alert_type: 'All',
  status: 'started',
};

const defaultWatchtowerValues = {
  alert_type: 'All',
  alert_trigger: 'All',
  alert_actioned: 'All',
  alert_irregular: 'All',
  alert_group: 'All',
  alert_pipeline: 'All',
  alert_futuretask: 'All',
  alert_date: 'All',
};

const defaultCustomerValues = {
  start_date: 'All',
  source: 'All',
  approved_at: 'All',
  branch: 'All',
  approver: 'All',
  region: 'All',
  state: 'All',
  application_type: 'All',
  entity_type: 'All',
  review_date: 'All',
  review_date_from: null,
  review_date_to: null,
  with_watchtower_alerts: 'All',
  ppsr_applies: 'All',
  limit_min: '',
  limit_max: '',
  sales_channel: 'All',
  start_date_from: null,
  start_date_to: null,
  approved_at_from: null,
  approved_at_to: null,
};

const defaultCardsValues = {
  supplier_id: 'All',
  card_issuing_reason: 'All',
  period: 'All',
};

const defaultDirectDebitValues = {
  supplier_id: 'All',
  period: 'All',
};

// TODO: This file needs to be updated to use consider
// different defaultValues from different type of index
function getDefaultValues(filterType) {
  if (filterType === 'alerts') {
    return defaultAlertsValues;
  }

  if (filterType === 'watchtower') {
    return defaultWatchtowerValues;
  }

  if (filterType === 'customer') {
    return defaultCustomerValues;
  }

  if (filterType === 'cards') {
    return defaultCardsValues;
  }

  if (filterType === 'direct-debit') {
    return defaultDirectDebitValues;
  }

  return defaultValues;
}

const validKeys = [
  'start_date_from',
  'start_date_to',
  'review_date_from',
  'review_date_to',
  'approved_at_from',
  'approved_at_to',
  ...Object.keys(defaultValues),
];

function getValidKeys(filterType) {
  if (filterType === 'alerts') {
    return [...Object.keys(defaultAlertsValues)];
  }

  return validKeys;
}

const keysFilter = (params) => {
  const res = {};
  Object.keys(params).forEach((key) => {
    if (getValidKeys(params.source_page).includes(key)) {
      res[key] = params[key];
    }
  });
  return res;
};

export default function useFilterState(searchParams, options = {}) {
  const [isOpen, setIsOpen] = useState(false);
  const [filterParams, setFilterParams] = useState({
    ...getDefaultValues(searchParams.source_page),
    ...keysFilter(searchParams),
  });
  const { isCallCentreActive } = options;

  const onToggleIsOpen = () => setIsOpen(!isOpen);
  const onChangeFilterParams = (params) => {
    let newParams = { ...filterParams, ...params, page: 1 };
    // reset call centre user if sales channel is not call centre
    if (
      newParams.call_centre_user !== 'All' &&
      newParams.sales_channel !== 'call_centre_channel'
    ) {
      newParams = { ...newParams, call_centre_user: 'All' };
    }
    setFilterParams(newParams);
  };

  const resetFilterParams = () => {
    setFilterParams(getDefaultValues(searchParams.source_page));
  };

  return {
    filter: (
      <FilterList
        isOpen={isOpen}
        reset={resetFilterParams}
        onChangeFilterParams={onChangeFilterParams}
        onToggleIsOpen={onToggleIsOpen}
        filterParams={filterParams}
        isCallCentreActive={isCallCentreActive}
      />
    ),
    filterParams,
    isOpen,
    onToggleIsOpen,
  };
}
