/* Import libraries */
import BorderedCalendarPicker from 'modules/shared/components/inputs/BorderedCalendarPicker';
import BorderedTextArea from 'modules/shared/components/inputs/BorderedTextArea';
import BorderedTextField from 'modules/shared/components/inputs/BorderedTextField';
import TextInput from 'modules/shared/components/inputs/TextInput';
import CheckBoxes from 'modules/shared/components/widgets/interactive/CheckBoxes';
import Autocomplete from 'modules/shared/components/widgets/interactive/Autocomplete';
import integerise from 'modules/shared/helpers/integerise';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { formatMoney } from 'utils/formatting';
import { isAllNumber, isValidEmail } from 'utils/validators';

import styles from './css/FormBuilder.css';

function FormBuilderComponentRender(props) {
  const { formParams, isDraft, isValid, preview, shouldValidate } = props;
  const defaultValues = {
    value: '',
    value_1: '',
    value_2: '',
  };
  const [state, setStates] = useState(props.answer || defaultValues);
  const [blurred, setIsBlurred] = useState(false);

  function setState(updatedState) {
    const newState = { ...state, ...updatedState };
    setStates(newState);
  }

  function isBlurred() {
    setIsBlurred(true);
  }

  function validate() {
    const { value, value_1, value_2 } = state;
    if (formParams.component_type === 'pair_question') {
      isValid && isValid(valid({ value_1, value_2 }));
    } else if (formParams.component_type === 'dropdown_menu') {
      const filteredValues = filterDropdownMenuValueOptions(value);
      isValid && isValid(valid(filteredValues));
    } else {
      isValid && isValid(valid(value));
    }
  }

  useEffect(() => {
    validate();
  }, [state]);

  useEffect(() => {
    if (shouldValidate) {
      setIsBlurred(true);
      validate();
    }
  }, [shouldValidate]);

  useEffect(() => {
    if (preview) {
      setStates(defaultValues);
    }
  }, [formParams]);

  useEffect(() => {
    validate();
  }, [isDraft]);

  function setValue(valueArg, keyName) {
    const { formParams, onChange, isValid } = props;
    const {
      accepted_value,
      component_type,
      field_description,
      field_description_1,
      field_description_2,
      options,
    } = formParams || {};
    const stateKeyName = keyName || 'value';

    const getDropdownValue = (options, value) => {
      return options
        .split('|')
        .filter((x) => value && x.split('(')[0].includes(value))[0];
    };

    let value = valueArg;

    if (component_type === 'dropdown_menu')
      if (Array.isArray(valueArg))
        value = valueArg.map((val) => getDropdownValue(options, val));
      else if (typeof valueArg === 'string')
        value = getDropdownValue(options, valueArg);

    if (accepted_value === 'amount') {
      value = integerise(value);
    }

    setState({ [stateKeyName]: value });

    if (component_type === 'pair_question') {
      let value_1;
      let value_2;
      if (keyName.slice(-1) === '1') {
        [value_1, value_2] = [value, state.value_2];
      } else {
        [value_1, value_2] = [state.value_1, value];
      }
      isValid && isValid(valid({ value_1, value_2 }));
      onChange &&
        onChange({
          description_1: field_description_1,
          description_2: field_description_2,
          value_1,
          value_2,
        });
    } else {
      isValid && isValid(valid(value));
      onChange && onChange({ description: field_description, value });
    }

    // Set blurred on non text based input
    if (!['short_question', 'long_question'].includes(component_type)) {
      isBlurred();
    }
  }

  function valid(value) {
    if (formParams.mandatory && !isDraft) {
      return mandatoryFieldCheck(value);
    }
    return optionalFieldCheck(value);
  }

  function mandatoryFieldCheck(value) {
    switch (formParams.component_type) {
      case 'pair_question':
        return (
          Boolean(value) && Boolean(value.value_1) && Boolean(value.value_2)
        );
      case 'long_question':
        return Boolean(value);
      case 'dropdown_menu':
        if (!formParams.mulit_select) return Boolean(value);
        if (!formParams.main_option) return Boolean(value) && value.length > 0;
        return Boolean(value) && Boolean(value.main);
      case 'check_boxes':
        return (
          Boolean(value) &&
          (formParams.main_option ? Boolean(value.main) : value.length > 0)
        );
      case 'short_question':
        return (
          Boolean(value) && valueFormatCheck(formParams.accepted_value, value)
        );
    }
  }

  function filterValues(arrayValues) {
    const { formParams } = props;

    const missingOptions = arrayValues.some((arrayValue) => {
      const options = dropdownMenuOptions(formParams.options);
      return !options.filter(({ value }) => value === arrayValue);
    });

    if (missingOptions) {
      return [];
    }

    return arrayValues;
  }

  function filterDropdownMenuValueOptions(value) {
    const { formParams } = props;
    if (!value || formParams.component_type !== 'dropdown_menu') {
      return value;
    }
    const multiSelect = formParams.mulit_select;
    const mainOption = formParams.main_option;
    const valueIsArray = Array.isArray(value);
    const arrayValues = valueIsArray || mainOption ? value : [value];
    let filteredValues;

    if (!multiSelect) {
      const filteredOption = filterValues([value])[0];

      if (!filteredOption) {
        return null;
      }

      return formattedDropdownOption(value).value;
    }

    if (mainOption) {
      if (!value) {
        return null;
      }

      const filteredMain = filterValues([value.main])[0];

      if (!filteredMain) {
        return null;
      }

      filteredValues = value;
    } else {
      // TODO - Review whether to exclude removed options from supplier
      // Not removing selected options now for dropdown doesn't have main option
      // since selected values are still valid to supplier
      filteredValues = filterValues(arrayValues);
      filteredValues = dropdownMenuOptions(filteredValues.join('|'));
      filteredValues = filteredValues.map(({ value }) => value);
    }

    return filteredValues;
  }

  function optionalFieldCheck(value) {
    const { formParams } = props;
    switch (formParams.component_type) {
      case 'pair_question':
        // for pair question, if any one has the value then the other must has value too
        if (!value) return true;
        return Boolean(value.value_1) === Boolean(value.value_2);
      case 'short_question':
        return !value || valueFormatCheck(formParams.accepted_value, value);
      case 'dropdown_menu':
      case 'check_boxes':
        if (
          formParams.main_option &&
          value &&
          value.other &&
          value.other.length > 0
        )
          return Boolean(value.main);
        return true;
      case 'long_question':
      case 'section_title':
        return true;
    }
  }

  function valueFormatCheck(valueType, value) {
    switch (valueType) {
      case 'email':
        return isValidEmail(value);
      case 'number':
        return isAllNumber(value);
      case 'amount':
        return !isNaN(integerise(value));
      default:
        return true;
    }
  }

  function errorMessage(pairIndex = 0) {
    const { formParams } = props;
    const { value } = state;

    const processedValue = filterDropdownMenuValueOptions(value);

    if (!processedValue) {
      if (
        (formParams.field_description_1 === 'Address of property ownership' ||
          formParams.field_description_1 ===
            'Name of mortgagee - bank/lender') &&
        !state.value_1 &&
        pairIndex === 0
      )
        return 'This field is mandatory.  If doesn’t apply enter n/a';

      if (
        (formParams.field_description_1 === 'Address of property ownership' ||
          formParams.field_description_1 ===
            'Name of mortgagee - bank/lender') &&
        !state.value_2 &&
        pairIndex === 1
      ) {
        return 'This field is mandatory.  If doesn’t apply enter $0.00';
      }

      if (
        formParams.main_option &&
        value &&
        !value.main &&
        value.other.length > 0
      ) {
        return 'Please select a main option.';
      }

      return 'This field is mandatory.';
    }

    if (formParams.main_option && !value.main) {
      return 'Please select your main option using the slider on the right hand under each option';
    }

    switch (formParams.accepted_value) {
      case 'email':
        return 'Please input a valid email.';
      case 'number':
        return 'Please input a valid number.';
      case 'amount':
        return 'Please input a valid amount.';
    }
    return 'This field is mandatory.';
  }

  function splitStringIntoOptions(string) {
    return string ? string.split('|') : [];
  }

  function formattedCheckBoxesOptions(string) {
    let checkOptions = {};
    if (string.includes(':')) {
      string.split(/(?:\r\n|\r|\n)/).forEach((category) => {
        const [categoryName, subString] = category.split(':');
        checkOptions[categoryName] = splitStringIntoOptions(subString);
      });
    } else {
      checkOptions = splitStringIntoOptions(string);
    }
    return checkOptions;
  }

  function dropdownMenuOptions(string) {
    if (!string) {
      return [];
    }

    const dropdownOptions = [];
    if (string.includes(':')) {
      string.split(/(?:\r\n|\r|\n)/).forEach((category) => {
        const [categoryName, subString] = category.split(':');
        splitStringIntoOptions(subString).forEach((option) => {
          const dropdownOption = formattedDropdownOption(option);
          dropdownOption.label = `${categoryName} - ${dropdownOption.label}`;
          dropdownOption.value = `${categoryName} - ${dropdownOption.value}`;
          dropdownOptions.push(dropdownOption);
        });
      });
    } else {
      splitStringIntoOptions(string).forEach((option) => {
        dropdownOptions.push(formattedDropdownOption(option));
      });
    }
    return dropdownOptions;
  }

  function formattedDropdownOption(string) {
    const descriptionStart = string.indexOf('(');
    const descriptionEnd = string.lastIndexOf(')');
    if (descriptionStart !== -1 && descriptionEnd !== -1) {
      return {
        label: string.slice(0, descriptionStart),
        description: string.slice(descriptionStart + 1, descriptionEnd),
        value: string.slice(0, descriptionStart),
      };
    }
    return { label: string, value: string };
  }

  function renderMoney(value) {
    if (value) {
      return `$${formatMoney(parseInt(value))}`;
    }
    return '$';
  }

  function formatPairQuestionValue2(value) {
    if (value === '$') return null;
    return parseInt(value.replace(/[^0-9]+/g, '') || '0').toString();
  }

  function renderFormComponent() {
    const {
      borderedStyle,
      labelShrink,
      name,
      editable,
      formParams,
      mode,
      showError,
    } = props;
    const { value, value_1, value_2 } = state;
    const TextField = borderedStyle ? BorderedTextField : TextInput;
    const MultilineTextField = borderedStyle ? BorderedTextArea : TextInput;
    const displayError = mode === 'onBlur' ? showError && blurred : showError;

    const labelProps = {
      InputLabelProps: {
        shrink: labelShrink,
      },
    };

    const getUniqueOptions = () => {
      const options = formParams.options || '';
      return Array.from(new Set(options.split('|'))).join('|');
    };

    switch (formParams.component_type) {
      case 'pair_question':
        return (
          <div>
            <div className={styles.half_width}>
              <TextField
                label={formParams.field_description_1}
                required={formParams.mandatory || Boolean(value_2)}
                showOptional
                value={value_1}
                onChange={(event) => {
                  setValue(event.target.value, 'value_1');
                }}
                error={displayError}
                {...labelProps}
              />
            </div>
            <div className={styles.half_width}>
              <TextField
                label={formParams.field_description_2}
                required={formParams.mandatory || Boolean(value_1)}
                showOptional
                value={renderMoney(value_2)}
                onChange={(event) => {
                  setValue(
                    formatPairQuestionValue2(event.target.value),
                    'value_2'
                  );
                }}
                error={displayError}
                {...labelProps}
              />
            </div>
          </div>
        );
      case 'check_boxes':
        const hasMultipleOptions = formParams.options.split('|').length > 1;
        const displayInRow = formParams.display === 'row';

        return (
          <div className={styles.checkbox_container}>
            <CheckBoxes
              borderedStyle={borderedStyle}
              onChange={(target) => {
                setValue(target.value);
              }}
              value={value}
              options={formattedCheckBoxesOptions(formParams.options)}
              optionsOneRow={hasMultipleOptions && !displayInRow ? 6 : 1}
              label={formParams.field_description}
              required={formParams.mandatory}
              hasMainOption={formParams.main_option}
              error={displayError && errorMessage()}
            />
          </div>
        );
      case 'dropdown_menu':
        const filteredValues = filterDropdownMenuValueOptions(value);
        return (
          <Autocomplete
            id={name}
            onChange={(value) => {
              setValue(value);
            }}
            label={formParams.field_description}
            value={filteredValues}
            required={formParams.mandatory}
            multiple={formParams.mulit_select}
            hasMainOption={formParams.main_option}
            error={displayError && errorMessage()}
            options={dropdownMenuOptions(getUniqueOptions())}
            borderedStyle={borderedStyle}
            labelShrink={labelShrink}
          />
        );
      case 'section_title':
        return (
          <div
            className={`has-text-weight-normal ${styles.section_title} ${
              editable && styles.section_title_readonly
            }`}
          >
            {formParams.field_description}
          </div>
        );
      case 'long_question':
        return (
          <MultilineTextField
            label={formParams.field_description}
            required={formParams.mandatory}
            showOptional
            value={value}
            multiline
            onChange={(event) => {
              setValue(event.target.value);
            }}
            onBlur={isBlurred}
            error={displayError && errorMessage()}
            helperText={displayError && errorMessage()}
            labelShrink={labelShrink}
          />
        );
      default:
        if (formParams.accepted_value === 'date') {
          return (
            <BorderedCalendarPicker
              id={name}
              label={formParams.field_description}
              required={formParams.mandatory}
              value={value === '' ? null : value}
              onChange={(value) => {
                setValue(value);
              }}
              onBlur={isBlurred}
              textFieldProps={{
                error: displayError,
                helperText: displayError && errorMessage(),
              }}
              isStyledUnderlined={!borderedStyle}
              labelShrink={labelShrink}
            />
          );
        }
        return (
          <TextField
            label={formParams.field_description}
            required={formParams.mandatory}
            showOptional
            value={value}
            number_only={formParams.accepted_value === 'amount'}
            onChange={(event) => {
              setValue(event.target.value);
            }}
            onBlur={isBlurred}
            error={displayError && errorMessage()}
            labelShrink={labelShrink}
            helperText={(displayError && errorMessage()) || ' '}
          />
        );
    }
  }

  return renderFormComponent();
}

export default connect((_state, ownProps) => {
  return {
    answer: ownProps.answer || { value: '', value_1: '', value_2: '' },
  };
})(FormBuilderComponentRender);
